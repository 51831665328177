<template>
    <el-dialog
        title="订单属性"
        :visible.sync="dialogVisibles"
        width="650px"
        custom-class='attributeDialog'
        :close-on-click-modal='false'
    >
        <div class='allbox'>
            <div class="toggleBox">
                <!-- 工作日 unelevated-->
                <div>
                    <q-btn-toggle
                        v-model="attributeAllDatas.weekDay"
                        class="my-custom-toggle"
                        no-caps
                        toggle-color="blue-6"
                        color="grey-3"
                        text-color="blue-3"
                        unelevated 
                        :options="[
                            {label: '周末', value: true},
                            {label: '工作日', value: false},
                        ]"
                        @input='weekDayChange'
                    />
                    <!-- <el-switch
                        v-model="attributeAllDatas.weekDay"
                        active-text="按月付费"
                        inactive-text="按年付费"
                    >
                    </el-switch> -->
                </div>
                <!-- 加急 -->
                <div>
                    <q-btn-toggle
                        v-model="attributeAllDatas.urgentStatus"
                        class="my-custom-toggle"
                        no-caps
                        toggle-color="blue-6"
                        color="grey-3"
                        text-color="blue-3"
                        unelevated 
                        :options="[
                            {label: '加急', value: true},
                            {label: '不加急', value: false}
                        ]"
                        @input='urgentStatusChange'

                    />
                </div>

                <!-- 支付 -->
                <div>
                    <q-btn-toggle
                        :disable='attributeAllDatas.payStatus == 1'
                        v-model="attributeAllDatas.payStatus"
                        class="my-custom-toggle"
                        no-caps
                        toggle-color="blue-6"
                        color="grey-3"
                        text-color="blue-3"
                        unelevated 
                        :options="[
                            {label: '支付', value: 1},
                            {label: '未支付', value: 0}
                        ]"
                        @input='payStatusChange'

                    />
                </div>
            </div>
            <!-- 备注区 -->
            <div class="checkDescBox">
                <div class='titbox'>
                    备注信息:
                </div>
                <div class='mainbox'>
                    <q-form ref="myForm" greedy class="add">
                        <q-input
                            :dense='true' 
                            outlined  
                            autogrow
                            type="textarea"
                            v-model="attributeAllDatas.checkDesc" 
                            label="订单备注信息"
                            :rules="[required.checkDesclength]"
                        />
                    </q-form>
                </div>
            </div>
        </div>

        <div slot="footer" class="dialog-footer">
            <el-button @click="dialogVisibles = false">取 消</el-button>
            <el-button type="primary" @click="infosave">保存</el-button>
        </div>
    </el-dialog>
</template>
<script>
import { orderInfo,orderCheckpay,orderCheckUrgent,orderCheckDesc,orderCheckOrderDay } from '@/api/orderCheck.js'; // 订单审核接口

import  {required}  from '@/api/components/required.js' // 表单验证规则
import { notifys } from '@/api/components/Notify.js'

export default {
    props:{
        dialogVisible:{
            type: Boolean,
            required: true
        },
        attributeAllData:{
            type: Object,
            required: true
        },
        attributeData:{
            type: Object,
            required: true
        },
    },
    data() {
        return {
            required, //规则

            value1:'one',
            textarea1:'',

            rules: {
                attribute: [
                    { required: true, message: '请输入活动名称', trigger: 'change' },
                    {min: 0, max: 100, message: '备注信息最大长度为100', trigger: 'change' }
                ],
            }
        }
    },
    created(){
    },
    computed:{
        dialogVisibles:{
            get(){
                return this.dialogVisible;
            },
            set(v){
                this.$emit('update:dialogVisible',v)
            }
        },
        attributeDatas:{
            get(){
                return this.attributeData;
            },
            set(v){
                this.$emit('update:attributeData',v)
            }
        },
        attributeAllDatas:{
            get(){
                return this.attributeAllData;
            },
            set(v){
                this.$emit('update:attributeAllData',v)
            }
        }
    },
    methods:{
        // 获取订单属性详细
        async getinfoData(){
            const {data:res} = await orderInfo({checkId:this.attributeAllDatas.checkId})
            console.log(res);
            if(res.code===20000){
                this.attributeAllDatas = res.data.info //储存总数据
            }
        },
        // 周末工作日变动
        async weekDayChange(v){
            console.log(v);
            this.$q.dialog({
                title: '周末或工作日切换',
                message: `你是否要修改此订单的工作时段状态?`,
                ok: { label: '确定'},
                cancel: { label: '取消',flat: true},
            }).onOk(async() => {
                try{
                    const { data: res } = await orderCheckOrderDay({ orderId: this.attributeDatas.orderId })
                    if(res.code===20000){
                        this.$emit('getorderCheckList')
                        this.getinfoData()
                        notifys('positive',res.message)
                    }else{
                        this.getinfoData()
                        notifys('negative',res.message)
                    }
                }catch (e){
                    console.log(e);
                }
            }).onCancel(() => {
                this.getinfoData()
                // console.log('Cancel')
            })
        },
        // 加急状态变动
        async urgentStatusChange(){
            this.$q.dialog({
                title: '修改加急状态',
                message: `你是否要修改此订单的加急状态?`,
                ok: { label: '确定'},
                cancel: { label: '取消',flat: true},
            }).onOk(async() => {
                try{
                    const { data: res } = await orderCheckUrgent({ orderId: this.attributeDatas.orderId })
                    console.log(res);
                    if(res.code===20000){
                        this.$emit('getorderCheckList')
                        this.getinfoData()
                        notifys('positive',res.message)
                    }else{
                        this.getinfoData()
                        notifys('negative',res.message)
                    }
                }catch (e){
                    console.log(e);
                }
            }).onCancel(() => {
                this.getinfoData()
                // console.log('Cancel')
            })
        },
        // 支付状态变动
        async payStatusChange(){
            this.$q.dialog({
                title: '支付状态',
                message: `你是否要将此订单的支付状态改为已支付?`,
                ok: { label: '确定'},
                cancel: { label: '取消',flat: true},
            }).onOk(async() => {
            try{
                const { data: res } = await orderCheckpay({ orderId: this.attributeDatas.orderId })
                console.log(res);
                if(res.code===20000){
                    this.$emit('getorderCheckList')
                    this.getinfoData()
                    notifys('positive',res.message)
                }else{
                    this.getinfoData()
                    notifys('negative',res.message)
                }
                }catch (e){
                    console.log(e);
                }
            }).onCancel(() => {
                this.getinfoData()
                // console.log('Cancel')
            })
        },
        // 点击保存时触发
        infosave(){
            this.$refs.myForm.validate().then( async outcome =>{
                if (outcome){
                    const {data:res} = await orderCheckDesc({
                        checkId:this.attributeAllDatas.checkId,
                        checkDesc: this.attributeAllDatas.checkDesc,
                    })
                    if(res.code===20000){
                        this.$emit('getorderCheckList')
                        this.getinfoData()
                        this.dialogVisibles = false
                        notifys('positive',res.message)
                    }else{
                        this.getinfoData()
                        notifys('negative',res.message)
                    }
                }
            })
        }
    },
}
</script>
<style lang="less" scoped>
.attributeDialog{
    max-width: 650px;
}
.allbox{
    padding: 0 20px;
}
.toggleBox{
    display: flex;
    justify-content: space-between;
    margin: 15px 0 25px 0;
}
.checkDescBox{
    display: flex;
    // padding: 0 15px; 
    .titbox{
        width: 80px;
        text-align: center;
        height: 40px;
        line-height: 40px;
    }
    .mainbox{
        width: 100%;
        line-height: 60px;
    }
}
</style>
