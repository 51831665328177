<template>
  <div id="OrderCheck" class="q-pa-md q-gutter-sm">
    <!-- 面包屑 -->
    <q-breadcrumbs>
      <q-breadcrumbs-el icon="iconfont icon-zhuye" label="主页" to="/orm/dashboard" />
      <q-breadcrumbs-el label="订单管理" icon="iconfont icon-dingdanguanli" />
      <q-breadcrumbs-el label="订单审核" icon="iconfont icon-ziliaoshenhe" to="/order/check/list" />
    </q-breadcrumbs>
    <div class='ordercheckbox' id="ordercheckbox">
      <!-- 搜索卡片区 -->
      <q-card class="my-card" style="margin-bottom: 10px;">
        <q-card-section>
          <div class="row" id="searchbox">
            <div class="col-9 search">
              <!-- <q-input class='operation' :dense='true' outlined  v-model="queryData.orderId" label="订单号" /> -->
              <q-input class='operation' :dense='true' outlined  v-model="queryData.patientName" label="患者姓名">
                <template v-if="queryData.patientName" v-slot:append>
                  <q-icon name="close" @click.stop="queryData.patientName = ''" class="cursor-pointer" style="font-size: 16px;"/>
                </template>
              </q-input>
              <!-- <selectq class='operation' :options='orderCheckStatus' :model.sync='checkStatus' :label='"订单审核状态"' /> -->
              <selectq class='operation' :options='orderCheckStatus' :model.sync='checkStatus' :label='"订单审核状态"' />
              <!-- <q-select
                outlined
                v-model="checkStatus"
                :options="orderCheckStatus"
                class="operation"
                label="订单审核状态"
                option-label="value"
                color="teal"
                :dense="true"
                options-selected-class="text-deep-orange"
              >
                  <template v-slot:option="scope">
                      <q-item
                          v-bind="scope.itemProps"
                          v-on="scope.itemEvents"
                      >
                          <q-item-section>
                              <q-item-label>{{
                                  scope.opt.value
                              }}</q-item-label>
                          </q-item-section>
                      </q-item>
                  </template>
              </q-select> -->
              <el-button
                :disabled='Permissionslist.filter(item=>item==="order.check.list").length>0?false:true'
                class='resetquery'
                type="primary"
                size='medium'
                @click='search'
              >
              搜索
              </el-button>
              <el-button
                :disabled='Permissionslist.filter(item=>item==="order.check.list").length>0?false:true'
                class='resetquery'
                type="primary"
                size='medium'
                @click='chongzhi'
              >
              重置
              </el-button>
            </div>
            <div class="col addbtn">
              <!-- :disabled='multipleSelection.length==0? true : false' -->
              <el-button
                :disabled='Permissionslist.filter(item=>item==="order.check.check").length>0?false:true'
                type="primary"
                size='medium'
                @click='Batchcheck'
              >
              审核通过
              </el-button>
              <el-button
                :disabled='Permissionslist.filter(item=>item==="order.check.check").length>0?false:true'
                type="primary"
                size='medium'
                @click='Batchreceive'
              >
              领取任务
              </el-button>
            </div>
          </div>
        </q-card-section>
      </q-card>
      <!-- 列表区域 -->
      <q-card class="my-card">
        <!-- <q-card-section class='tabletop'>
          
        </q-card-section> -->
        <q-card-section >
          <el-table
          v-loading="ordervisible"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(55,55,55,0.2)"
          @sort-change="sortthiscolumn"
          ref="multipleTable"
          :data="orderchecklist"
          tooltip-effect="dark"
          style="width: 100%"
          :max-height="tableHeight"
          @selection-change="handleSelectionChange"
          >
          <template slot="empty">
            {{nodatetype}}
          </template>
          <el-table-column align='center' type="selection" width="55" fixed="left"></el-table-column>
          <el-table-column v-if="orderID" sortable="custom" show-overflow-tooltip align='center' prop="orderId" label="订单号" min-width="93px"></el-table-column>
          <el-table-column v-if="patient" sortable="custom" align='center' prop="patientName" label="患者姓名" min-width='104px'></el-table-column>
          <!-- 加急订单 -->
          <el-table-column v-if="Urgent && urgenthide" align='center' width='60px'>
            <template slot-scope="{row}">
              <el-tag v-if='row.urgentStatus' type="danger" style='padding:0 5px;'>加急</el-tag>
              <el-tag v-if='row.weekDay' type="danger" style='padding:0 5px;'>周末</el-tag>
            </template>
          </el-table-column>
          <!-- 主管医师 --> 
          <el-table-column v-if="attendingDoctor" sortable="custom" show-overflow-tooltip align='center' prop="attendingDoctor" label="主管医师" min-width='104px'></el-table-column>
          <el-table-column v-if="doctor" sortable="custom" align='center' prop="doctorName" label="医生姓名"  min-width='104px'></el-table-column>
          <el-table-column v-if="createU" sortable="custom" show-overflow-tooltip align='center' prop="createUser" label="下单人" min-width="93px"></el-table-column>
          <el-table-column v-if="combo" sortable="custom" show-overflow-tooltip align='center' prop="comboName" label="套餐"></el-table-column>
          <el-table-column v-if="province" sortable="custom" show-overflow-tooltip align='center' prop="provinceName" label="省份"></el-table-column>
          <el-table-column v-if="city" sortable="custom" show-overflow-tooltip align='center' prop="cityName" label="城市"></el-table-column>
          <el-table-column v-if="hos" sortable="custom" show-overflow-tooltip align='center' prop="hospName" label="医院"></el-table-column>
          <el-table-column v-if="depart" sortable="custom" show-overflow-tooltip align='center' prop="departName" label="科室"></el-table-column>
          <el-table-column v-if="Amt" sortable="custom" align='center' prop="orderAmt" label="订单金额" min-width='104px'></el-table-column>
          <el-table-column v-if="sTime" sortable="custom" align='center' prop="surgeryDate" label="手术时间" min-width='151px'></el-table-column>
          <el-table-column v-if="cTime" sortable="custom" align='center' prop="createTime" label="创建时间" min-width='151px'></el-table-column>
          <!-- <el-table-column align='center' prop="orderType" label="订单类型" >
            <template slot-scope="orderTypes">
              {{ (orderType.filter(item => item.key == orderTypes.row.orderType ).pop() || {}).value }}
            </template>
          </el-table-column> -->
          <!-- 代理商是否收款 -->
          <el-table-column v-if="pay" sortable="custom" min-width="143px" align='center' prop="isPay" label="代理商是否收款" >
            <template slot-scope="scope">
              <q-chip v-show="scope.row.isPay!=null" square :color="scope.row.isPay==true?'positive':'warning'" text-color="white">
                {{ (isPay.filter(item=>item.key == scope.row.isPay).pop() || {} ).value }}
              </q-chip>
            </template>
          </el-table-column>
          <!-- 订单类型 -->
          <el-table-column v-if="orderT" sortable="custom" align='center' prop="orderType" label="订单类型" min-width="117px" >
            <template slot-scope="scope">
              <q-chip v-show="scope.row.orderType!=null" square :color="orderTypecolor[scope.row.orderType]" text-color="white">
                {{ (orderType.filter(item=>item.key == scope.row.orderType).pop() || {} ).value }}
              </q-chip>
            </template>
          </el-table-column>
          <!-- 支付状态 -->
          <el-table-column v-if="payS" sortable="custom" align='center' prop="payStatus" label="支付状态" min-width="100px" >
            <template slot-scope="payStatus">
              <q-chip square :color="payStatuscolor[payStatus.row.payStatus]" text-color="white">
                {{ payStatus.row.payStatus==0? '待支付':'已支付' }}
              </q-chip>
            </template>
          </el-table-column>
          <!-- 资料审核状态 -->
          <el-table-column v-if="informationS" sortable="custom" align='center' prop="informationStatus" label="资料审核状态" min-width="128px" >
            <template slot-scope="Types">
              <q-chip square :color="informationStatuscolor[Types.row.informationStatus]" text-color="white">
                {{ (informationStatus.filter(item => item.key == Types.row.informationStatus ).pop() || {}).value }}
              </q-chip>
            </template>
          </el-table-column>
          <!-- 审核状态 -->
          <el-table-column v-if="checkS" sortable="custom" align='center' prop="checkStatus" label="审核状态" min-width="100px">
            <template slot-scope="checkStatus">
              <q-chip square :color="checkStatuscolor[checkStatus.row.checkStatus]" text-color="white" class='qchip'>
                {{ (orderCheckStatus.filter(item => item.key == checkStatus.row.checkStatus).pop() || {}).value }}
              </q-chip>
            </template>
          </el-table-column>
          <!-- 订单状态 -->
            <el-table-column v-if="orderS" sortable="custom" align='center' prop="orderStatus" label="订单状态" min-width="117px" >
              <template slot-scope="scope">
                <q-chip square :color="orderStatuscolor[scope.row.orderStatus]" text-color="white">
                  {{ (orderStatus.filter(item=>item.key == scope.row.orderStatus).pop() || {} ).value }}
                </q-chip>
              </template>
            </el-table-column>
          <el-table-column align='center' label="操作"  min-width="230" width="250" fixed="right">
            <template slot="header">
              <div class='newCaozuobox'>
                <div class='czTit'>操作</div>
                <div class='czpopoverbtn'>
                  <el-popover
                    placement="bottom"
                    width="170"
                    trigger="click">
                      <q-checkbox v-model="orderID" label="订单号" color="cyan" />
                      <q-checkbox v-model="patient" label="患者姓名" color="cyan" />
                      <q-checkbox v-model="Urgent" label="订单加急" color="cyan" />
                      <q-checkbox v-model="combo" label="套餐" color="cyan" />
                      <q-checkbox v-model="attendingDoctor" label="主管医师" color="cyan" />
                      <q-checkbox v-model="doctor" label="医生姓名" color="cyan" />
                      <q-checkbox v-model="createU" label="下单人" color="cyan" />
                      <q-checkbox v-model="Amt" label="订单金额" color="cyan" />
                      <q-checkbox v-model="province" label="省份" color="cyan" />
                      <q-checkbox v-model="city" label="城市" color="cyan" style='margin-right: 8px;'/>
                      <q-checkbox v-model="hos" label="医院" color="cyan"  style='margin-right: 8px;'/>
                      <q-checkbox v-model="depart" label="科室" color="cyan" />
                      <q-checkbox v-model="sTime" label="手术时间" color="cyan" />
                      <q-checkbox v-model="cTime" label="创建时间" color="cyan" />
                      <q-checkbox v-model="pay" label="代理商是否收款" color="cyan" />
                      <q-checkbox v-model="orderT" label="订单类型" color="cyan" />
                      <q-checkbox v-model="payS" label="支付状态" color="cyan" />
                      <q-checkbox v-model="informationS" label="资料审核状态" color="cyan" />
                      <q-checkbox v-model="checkS" label="审核状态" color="cyan" />
                      <q-checkbox v-model="orderS" label="订单状态" color="cyan" />
                    <el-button icon="el-icon-s-grid" size='medium' slot="reference" style="padding: 5px 10px;border: none;">
                      <i class="el-icon-caret-bottom"></i>
                    </el-button>
                  </el-popover>
                </div>
              </div>
            </template>
            <template slot-scope="scope">
              <!-- 领取 -->
              <q-btn  
                :disable='Permissionslist.filter(item=>item==="order.check.check").length>0 && scope.row.checkStatus==0? false : true'
                class="czbtn" 
                size="sm" 
                color="blue" 
                icon="iconfont icon-lingqu" 
                @click="Checkclaim(scope.row)">
                <q-tooltip content-class="bg-blue" :offset="[10, 10]">
                  领取
                </q-tooltip>  
              </q-btn> 
              <!-- 审核@click='Checkagree' -->
              <q-btn 
                :disable='Permissionslist.filter(item=>item==="order.check.check").length>0 && scope.row.checkStatus!=0? false : true'
                class="czbtn"  
                size="sm" 
                color="green-5" 
                icon="iconfont icon-dingdanshenhe" 
                @click='Checkagree(scope.row)'>
                <q-tooltip content-class="bg-green-5" :offset="[10, 10]">
                  审核
                </q-tooltip>  
              </q-btn>
              <el-popover
                width='80'
                trigger="hover"
              >
                <q-btn
                  icon="iconfont icon-shuxing"
                  style="width:100%;"
                  class="czbtn2"  
                  size="sm"
                  color="yellow-10" 
                  @click="attribute(scope.row)"
                >
                  订单属性
                </q-btn>

                <q-btn
                  :disable='Permissionslist.filter(item=>item==="order.check.list").length>0?false:true'
                  style="width:100%;"
                  class="czbtn2"  
                  size="sm"
                  color="yellow-10" 
                  icon="iconfont icon-sanweimoxing" @click="getinformation(scope.row)"
                >
                  重建结果
                </q-btn>
                <q-btn
                  :disable='Permissionslist.filter(item=>item==="order.check.list").length>0?false:true'
                  style="width:100%;"
                  class="czbtn2"  
                  size="sm"
                  color="yellow-10" 
                  icon="iconfont icon-lishi" @click="gethistory(scope.row)"
                >
                  处理历史
                </q-btn>
                <q-btn
                  :disable='Permissionslist.filter(item=>item==="order.check.edit").length>0 ? false : true'
                  style="width:100%;"
                  class="czbtn2"  
                  size="sm"
                  color="yellow-10" 
                  icon="iconfont icon-fapiao" @click="fapiao(scope.row)"
                >
                  发票上传
                </q-btn>
                <el-button class="morebtn" size="mini" icon="el-icon-more" slot="reference">更多</el-button>
              </el-popover>
            </template>
          </el-table-column>
          
          </el-table>
         <!-- 分页组件 -->
        <elementPagination class="Paginationstyle" :total='total' :queryData='queryData' @getList='getorderCheckList()' @changepageSize='changepageSize' @changePage='changePage'/>
        </q-card-section>
      </q-card>
      <!-- 查看重建结果 -->
      <el-dialog :visible.sync="informationconfirm" width="450px" title="查看重建结果" :close-on-click-modal='false' @close="information=false">
        <informationresult
          class="el-dialog__body"
          ref='informationresult'
          :resultlist='resultlist'
          :imgsrc='imgsrc'
          :imgurl='imgurl'
          :huanzhe.sync='huanzhe'
        >
        </informationresult>
      </el-dialog> 
      <!-- 查看订单历史记录 -->
      <el-dialog :visible.sync="historyconfirm" width="60%" title="查看订单处理历史" :close-on-click-modal='false' @close="historyconfirm=false">
        <history
          class="el-dialog__body"
          ref='informationresult'
          :datum='reconstructionTaskInfos'
          :orderChecks='orderChecks'
        >
        </history>
      </el-dialog> 
      <!-- 审核任务 -->
      <el-dialog :visible.sync="agreeconfirm" width="70%" title="订单审核" :close-on-click-modal='false' @close="agreeconfirm = false">
        <checktask
          v-if="agreeconfirm"
          class="el-dialog__body"
          ref='checkagree'
          :checkAlllist.sync='checkAlllist'
          :checkAllData.sync='agreeAllData'
          :checkData.sync='agreeData'
          @closedialog='agreeconfirm = false'
          @checksuccess='getorderCheckList()'
        >
        </checktask>
      </el-dialog> 

      <!-- 订单属性 -->
      <orderAttribute
        :dialogVisible.sync="attributeVisible"
        :attributeData.sync='attributeData'
        :attributeAllData.sync='attributeAllData'
        @getorderCheckList='getorderCheckList'
      />

      <!-- 上传发票 -->
      <el-upload
        class="upload-demo upload"
        ref="invoiceupload"
        action=""
        style='display:none'
        :show-file-list="true"
        :on-change='invoiceFileschange'
        :file-list="invoiceFilesList"
        :auto-upload="false"
      >
          <el-button slot="trigger" size="small" id='Ibtn' type="primary"></el-button>
      </el-upload>

      <!-- 上传文件提示区 -->
      <q-dialog v-model="alert" persistent>
        <q-card style="width: 400px;">
          <q-card-section>
            <div class="wenjian">文件: {{fileName}}</div>
          </q-card-section>

          <q-card-section class="q-pt-none">
            <q-linear-progress size="25px" :value="checkprogress" color="primary">
              <div class="absolute-full flex flex-center">
                <q-badge color="white" text-color="primary" :label="checkprogressLabel" />
              </div>
            </q-linear-progress>
          </q-card-section>
          <q-card-section class="q-pt-none">
            
          </q-card-section>
        </q-card>
      </q-dialog>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import QRCode from 'qrcode'  // 生成二维码插件
import { makeDebounce } from "@/utils/debounce.js";  
import selectq from '@/components/Order/select/index2.vue' // 下拉框组件
import history from '@/components/OrderCheck/history' // 历史流程图组件
import checktask from '@/components/OrderCheck/orderCheckagree.vue' // 审核任务组件
import orderAttribute from '@/components/OrderCheck/orderAttribute.vue' // 订单属性组件

import elementPagination from '@/components/ElementUI/elementPagination.vue' // 分页组件
import informationresult from '@/components/Order/informationresult.vue' // 查看重建订单组件
import { getprovince,getcity,gethosplist,getdepart,getcombo,getoptions,optionget } from '@/api/components/checkcitys.js'; // 获取城市接口
import { 
orderCheck,
orderCheckList,
orderCheckclaim,
orderCheckinvoice,
orderCheckresult,
orderCheckpay,
orderCheckhistory,
orderCheckagree,
orderCheckUrgent,
orderInfo,
} from '@/api/orderCheck.js'; // 订单审核接口
import { discountCodeCheck } from '@/api/Discount.js'

import { getHeight } from '@/api/getHeight.js'; // 获取列表最高高度
import jwt from "jsonwebtoken";
import {
  getItem,
} from '@/utils/storage.js'
import { notifys } from '@/api/components/Notify.js'


export default {
    created(){
      this.getorderCheckList()
      document.onkeydown=e=>{
        let _key = window.event.keyCode;
        if(_key===13){
          this.search()
        }  
      }
    },
    data() {
      return {
        tableHeight:200, // 列表最大高度

        ordervisible: false, //控制表格loading
        nodatetype:'', //无内容时显示
        //城市列表
        checkAlllist:{
          province:[],
          city:[],
          hosp:[],
          depart:[],
          combo:[],
        },
        payStatuscolor:{ //支付状态
          0: 'primary',
          1: 'positive',
        },
        checkStatuscolor:{ //订单状态
          0: 'primary',
          1: 'primary',
          2: 'negative',
          3: 'positive',
        },
        informationStatuscolor:{ //资料状态
          0: 'primary', //资料待审核
          1: 'primary', //资料审核中
          2: 'negative', //资料被驳回
          3: 'indigo-5', //资料待重建
          4: 'indigo-5', //三维重建中
          5: 'indigo-5', //重建结果已上传
          6: 'indigo-5', //重建结果待审核
          7: 'indigo-5', //重建结果审核中
          8: 'negative', //重建结果被驳回
          9: 'positive', //重建完成
        },
        orderTypecolor:{
          0: 'positive',
          1: 'primary',
        },
        orderStatuscolor:{
          0: 'warning',
          1: 'primary',
          2: 'primary',
          3: 'negative',
          4: 'primary',
          5: 'positive',
        },

        patient: true, //控制患者列
        combo: true, //控制套餐列
        cTime: true, //控制创建时间
        checkS: true, //控制审核状态
        orderS: true, //控制订单状态
        hos: true, //控制医院
        informationS: true, //控制审核状态
        Urgent: true, //控制订单加急

        attendingDoctor:false, //主管医师
        doctor: false, //控制医生列
        depart: false, //控制科室
        payS: false, //控制支付状态
        Amt: false, //控制金额
        orderID: false, //控制订单号
        createU: false, // 控制下单人
        orderT: false, // 控制订单类型
        province: false, //控制省份
        city: false, // 控制城市
        sTime: false, //控制手术时间
        pay: false, //控制代理商是否收款

        alert: false, // 控制上传文件提示
        checkprogress: 0, //进度条
        checkprogressLabel: '', //进度百分比
        fileName: '', // 文件名

        // 分页设置
        queryData:{ // element分页信息
          page: 1,
          pageSize: 10,
          patientName:'', //患者姓名
        },
        checkStatus:'', //订单审核状态
        total:0, //总条数
        sortField:'', // 控制那列排序
        sortType:'', // 控制排序类型
        
        orderchecklist:[], //订单审核列表数据
        urgenthide: false,//是否展示加急列
        multipleSelection:[], //列表复选框选择的值

        invoiceFilesList:[], // 文件传输绑定数组
        invoiceList:[], //所选发票文件
        orderId:'', // 所选订单的id

        informationconfirm: false, // 控制重建结果弹窗
        resultlist:{}, // 重建结果信息
        imgsrc: '', //二维码生成地址
        imgurl:'', //二维码生成网址
        huanzhe:'', //患者姓名

        historyconfirm: false, // 控制查看历史记录
        reconstructionTaskInfos: null, //资料审核流程
        orderChecks: null, //订单审核流程

        agreeconfirm: false, //控制审核
        agreeAllData:null, // 查看订单的总信息
        agreeData:{ // 查看用户所需数据
          provinceoptions: null, // 省份所选项
          cityoptions: null, // 城市所选项
          hospoptions: null, // 医院所选项所选项
          departoptions: null, // 科室所选项
          combooptions: null, // 套餐所选项
          orderTypeoptions: '', // 订单类型所选项
          orderStatusoptions: null, // 订单状态所选项
          payStatusoptions: '', // 支付状态
          checkReportFilesList:[],//检查报告资料
          dcmReportFilesList:[], //影像资料报告
          invoiceList: [], // 发票信息
          ispayoptions: '', // 代理商是否付款
          orderCheckId:'', // 审核任务id
          disable: null, //控制是否可以驳回和审核通过
          reject:'', //驳回信息
          surgeryDate:'', //手术时间
          payId:'',// 订单流水

          returnedAmtDisable: null, //控制是否显示回款金额
          userType:'', //用户类型
          discountCode:'', //暂时的优惠码
          discountPercent:'', //折扣力度
          urgentAmt:'', //加急金额
          orderReturnedAmt:'', //回款金额
        },

        attributeVisible: false, //控制订单属性弹窗
        attributeAllData:{}, //订单属性详细信息
        attributeData:{}, //订单所有信息
      };
    },
    components:{
      selectq, // 普通下拉框组件
      elementPagination, //分页组件
      informationresult, //查看重建结果组件
      history, // 查看历史经常组件
      checktask, // 审核组件
      orderAttribute, //订单属性组件
    },
    computed:{
      ...mapState([
        'orderCheckStatus', //订单审核状态
        'orderType', //订单类型
        'orderStatus', //订单状态
        'orderType',//订单类型
        'payStatus', // 支付状态
        'isPay', //代理商付款状态
        'Permissionslist', // 权限词典
        'informationStatus',
      ]),
    },
    watch:{
    },
    filters:{
    
    },
    methods: {
      //批量领取
      Batchreceive(){
        const data = this.multipleSelection.map(item=>{
          return item.orderCheckId
        })
        console.log(data);
        this.$q.dialog({
          title: '批量领取',
          message: `你确定要领取已勾选的订单审核任务吗?`,
          ok: { label: '确定'},
          cancel: { label: '取消',flat: true},
        }).onOk(async() => {
          try{
              const { data: res } = await orderCheckclaim({ ids: data.join(',') })
              console.log(res);
              if(res.code===20000){
                this.getorderCheckList()
                notifys('positive',res.message)
              }else{
                notifys('negative',res.message)
              }
            }catch (e){
                console.log(e);
            }
        })
      },
      Batchcheck(){ // 批量审核
        const data = this.multipleSelection.map(item=>{
          return item.orderCheckId
        })
        console.log(data);
        this.$q.dialog({
          title: '批量审核',
          message: `你确定要让已勾选的订单审核任务全部通过吗?`,
          ok: { label: '确定'},
          cancel: { label: '取消',flat: true},
        }).onOk(async() => {
          try{
              const { data: res } = await orderCheckagree({ ids: data.join(',') })
              console.log(res);
              if(res.code===20000){
                this.getorderCheckList()
                notifys('positive',res.message)
              }else{
                notifys('negative',res.message)
              }
            }catch (e){
                console.log(e);
            }
        })
      },
      // 单个领取
      async Checkclaim(row){
        console.log(row);
        this.$q.dialog({
          title: '领取',
          message: `你是否要领取此订单审核任务?`,
          ok: { label: '确定'},
          cancel: { label: '取消',flat: true},
        }).onOk(async() => {
          try{
              const { data: res } = await orderCheckclaim({ ids: row.orderCheckId })
              console.log(res);
              if(res.code===20000){
                this.getorderCheckList()
                notifys('positive',res.message)
              }else{
                notifys('negative',res.message)
              }
            }catch (e){
                console.log(e);
            }
        })
      },
      // 获取订单审核列表
      getorderCheckList(ordername,sortType){
        this.nodatetype=''
        this.ordervisible = true
        this.urgenthide = false //默认隐藏加急列
        setTimeout(async() =>{
          const { data: res } = await orderCheckList({
            ...this.queryData,
            checkStatus: this.checkStatus.key,
            sortField: ordername, //给哪一列排序
            sortType: sortType, //排序类型升序或降序
          })
          console.log(res);
          if(res.code===20000){
            this.orderchecklist = res.data.orders
            this.orderchecklist.map((e)=>{
              if(e.urgentStatus || e.weekDay){
                //有加急订单 或者是有 周末订单
                this.urgenthide = true //显示加急列
              }
            })
            this.total = res.data.totals * 1
          }

          let height = document.getElementById('searchbox').offsetHeight + 60
          this.tableHeight = getHeight('ordercheckbox',height)

          this.ordervisible = false
          this.nodatetype='暂无数据'
        },500)
        
      },
      sortthiscolumn(column){
        console.log(column);
        console.log(column.order);
        if(column.order=='ascending'){

          if(column.prop=='provinceName'){
            this.getorderCheckList('provinceId',1)
            this.sortField = 'provinceId'
            this.sortType = 1
          }else if(column.prop=='cityName'){
            this.getorderCheckList('cityId',1)
            this.sortField = 'cityId'
            this.sortType = 1
          }else if(column.prop=='hospName'){
            this.getorderCheckList('hospId',1)
            this.sortField = 'hospId'
            this.sortType = 1
          }else if(column.prop=='comboName'){
            this.getorderCheckList('comboId',1)
            this.sortField = 'comboId'
            this.sortType = 1
          }else if(column.prop=='departName'){
            this.getorderCheckList('departId',1)
            this.sortField = 'departId'
            this.sortType = 1
          }else{
            // ascending 为升序
            this.getorderCheckList(column.prop,1)
            this.sortField = column.prop
            this.sortType = 1
          }
          
        }else if(column.order=='descending'){

          if(column.prop=='provinceName'){
            this.getorderCheckList('provinceId',0)
            this.sortField = 'provinceId'
            this.sortType = 0
          }else if(column.prop=='cityName'){
            this.getorderCheckList('cityId',0)
            this.sortField = 'provinceId'
            this.sortType = 0
          }else if(column.prop=='hospName'){
            this.getorderCheckList('hospId',0)
            this.sortField = 'provinceId'
            this.sortType = 0
          }else if(column.prop=='comboName'){
            this.getorderCheckList('comboId',0)
            this.sortField = 'provinceId'
            this.sortType = 0
          }else if(column.prop=='departName'){
            this.getorderCheckList('departId',0)
            this.sortField = 'provinceId'
            this.sortType = 0
          }else{
            // ascending 为降序
            this.getorderCheckList(column.prop,0)
            this.sortField = column.prop
            this.sortType = 0
          }
    
        }else{
          // 默认按照时间排序排序
          this.getorderCheckList('createTime',0)
          this.sortField = 'createTime'
          this.sortType = 0
        }
      },
      // 点击上传发票
      fapiao(row){
        this.orderId=row.orderId
        const I = document.getElementById('Ibtn')
        I.click()
      },
      // 发票文件改变时
      invoiceFileschange(file, fileList){ //发票上传文件发生改变时
        this.fileName = file.name
        const isLt = file.size / 1024/ 1024 / 1024 / 2 <= 1;
        if (!isLt) {
          notifys('negative','上传文件不得大于2G!')
          for(var i = 0;i<fileList.length;i++) {
              if(fileList[i].uid == uid) {
                  fileList.splice(i,1)
              }
          }
          return
        }
        this.invoiceList.push(file.raw)
        this.checkReportsave()
        this.invoiceList=[]
        fileList=[]
        this.orderId=''
      },
      // 发票上传
      async checkReportsave(){ 
        this.alert = true
        const filedata = new FormData()
        filedata.append('invoiceFile',this.invoiceList[0])
        filedata.append('orderId',this.orderId)
        // console.log(filedata);
        const { data: res } = await orderCheckinvoice(filedata, event=>{
          console.log(event);
          this.checkprogress = event.loaded/event.total
          this.checkprogressLabel=`文件已上传${((event.loaded/event.total)*100).toFixed(2)}%`
        })
        console.log(res);
        if(res.code ==20000){
          this.$refs.invoiceupload.clearFiles()
          notifys('positive',res.message)
        }else{
          notifys('negative',`文件${this.invoiceList[0].name},${res.message}`)
        }
        this.alert = false
      },
      //动态拼接二维码地址
      insertStr(soure, start, newStr){   
        return soure.slice(0, start) + newStr + soure.slice(start);
      },
      //点击重建结果时
      getinformation: makeDebounce(async function(row){ 
        let wPath = window.document.location.href;
        let pathName =  this.$route.path;
        let pos = wPath.indexOf(pathName);
        let localhostPath = wPath.substring(0, pos);
        let pathindex = localhostPath.indexOf('#')
        var newpath =this.insertStr(localhostPath,pathindex,"mobile/");
        console.log(row);
        const { data: res } = await orderCheckresult({orderId:row.orderId})
        console.log(res);
        if(res.code ===20000){
          this.informationconfirm=true
          this.resultlist = res.data.result
          QRCode.toDataURL(`${newpath}/wechat/viewer?code=${this.resultlist.accessCode}`, {  width: 200, height: 200 },(err, url)=>{
            this.imgsrc = url
          })
          this.imgurl =`${newpath}/wechat/viewer?code=${this.resultlist.accessCode}`
          this.huanzhe =row.patientName
        }else{
          notifys('info',res.message)
        }
      },500),

      //查看订单历史记录
      gethistory: makeDebounce(async function(row){
        const { data: res } = await orderCheckhistory({orderId:row.orderId})
        console.log(res);
        
        if(res.code ===20000){
          this.reconstructionTaskInfos = res.data.reconstructionTaskInfos //资料审核流程
          this.orderChecks = res.data.orderChecks //订单审核流程
          this.historyconfirm= true
          console.log(this.reconstructionTaskInfos);
          console.log(this.orderChecks);
        }
      }),
      //点击审核时
      Checkagree:makeDebounce(async function(row){
        const token = getItem('token');
        this.agreeData.userType = jwt.decode(token).userType
        // console.log(row);
        this.agreeData.orderCheckId = row.orderCheckId
        const {data:res} = await orderCheck({id:row.orderId})
        console.log(res);
        if(res.code===20000){
          this.agreeAllData = res.data.order //储存总数据
          console.log(this.agreeAllData);
          this.agreeData.disable = this.agreeAllData.orderCheck.checkStatus == 1? false: true;
          // 控制是否显示回款金额 只有商务和系统管理员可以显示回款金额
          this.agreeData.returnedAmtDisable = this.agreeData.userType ==2 || this.agreeData.userType ==3 ? true : false, 

          // 回款金额
          this.agreeData.orderReturnedAmt = this.agreeAllData.orderReturnedAmt ? this.agreeAllData.orderReturnedAmt: ''
          // 加急金额
          this.agreeData.urgentAmt = this.agreeAllData.urgentAmt ? this.agreeAllData.urgentAmt: ''
          // 优惠码
          this.agreeData.discountCode = this.agreeAllData.discountCode ? this.agreeAllData.discountCode: ''
          //折扣力度  
          this.agreeData.discountPercent = this.agreeAllData.discountPercent ? this.agreeAllData.discountPercent: ''
          //支付流水号
          this.agreeData.payId = this.agreeAllData.orderPay ? this.agreeAllData.orderPay.payId : ''

          this.agreeData.checkReportFilesList = [] //清空检查报告
          this.agreeData.dcmReportFilesList =[] // 清空影像资料
          this.agreeData.invoiceList=[] // 清空发票信息
          this.agreeconfirm = true

          // console.log("city list", this.checkAlllist.city, this.checkAlllist.city.filter(item => item.id==3));
          // console.log("this.agreeData.provinceoptions", this.agreeData.provinceoptions)

          this.agreeData.orderTypeoptions=this.agreeAllData.orderType!=undefined? optionget(this.orderType,this.agreeAllData.orderType) : ''  //订单类型

          this.agreeData.ispayoptions=this.agreeAllData.isPay!=undefined? optionget(this.isPay,this.agreeAllData.isPay) : ''  //代理商是否付款

          this.agreeData.surgeryDate=this.agreeAllData.surgeryDate!=undefined? this.agreeAllData.surgeryDate : ''  //手术时间

          this.agreeData.orderStatusoptions=optionget(this.orderStatus,this.agreeAllData.orderStatus) // 订单状态
          this.agreeData.payStatusoptions=optionget(this.payStatus,this.agreeAllData.payStatus) // 支付状态
          // 获取订单驳回信息
          if(this.agreeAllData.orderCheck){
            this.agreeData.reject = `${this.agreeAllData.orderCheck.comments}`
          }
          // console.log(this.agreeAllData.informationList);
          if(this.agreeAllData.informationList){
            this.agreeAllData.informationList.map(item=>{
              if(item.informationType == 0){
                this.agreeData.checkReportFilesList.push(item)
              } else{
                this.agreeData.dcmReportFilesList.push(item)
              }
            })
          }
          if(this.agreeAllData.invoice){
            this.agreeData.invoiceList.push(this.agreeAllData.invoice)
          }
        }
      }),

      // 打开订单属性弹框
      attribute:makeDebounce(async function(row){
        const {data:res} = await orderInfo({checkId:row.orderCheckId})
        console.log(res);
        if(res.code===20000){
          this.attributeData = row
          this.attributeAllData = res.data.info //储存总数据

          // this.attributeData.checkDesc = this.attributeAllData.checkDesc ? this.attributeAllData.checkDesc : '' //储存审核备注

          this.attributeVisible = true
        }
      },500),

      // 复选框被选择时
      handleSelectionChange(val) {
        this.multipleSelection = val;
      },
      // 搜索
      search:makeDebounce(function(){
        this.queryData.page = 1
        this.getorderCheckList(this.sortField,this.sortType)
      },200),
      //点击重置时
      chongzhi:makeDebounce(function(){
        this.queryData.page = 1
        this.queryData.orderId = ''
        this.queryData.patientName = ''
        this.checkStatus =''
        this.getorderCheckList()
      },200),
      // 分页被修改时
      changepageSize(val){
        this.queryData.pageSize = val
        this.getorderCheckList(this.sortField,this.sortType)
      },
      changePage(val){
        this.queryData.page = val
        this.getorderCheckList(this.sortField,this.sortType)
      }
    },
};
</script>

<style lang='less' scoped>
  #OrderCheck{
    height: 100%;
  }
  .ordercheckbox{
    height: 90%;
  }
  .search{
    display: flex;
    flex-direction: row ;
    align-items: flex-end;
    flex-wrap: wrap;
     .operation{
        margin: .625rem .625rem 0 .625rem;
        width: 12.5rem;
      }
  }
  .q-card__section--vert {
    padding: 10px;
  }
  /deep/.el-table td{
    padding: 8px 0 !important;
  }
  .newCaozuobox{
    // display: flex;
    // justify-content: space-between;
    .czTit{
      float: left;
      text-align: right;
      width:50%;
    }
    .czpopoverbtn{
      float: left;text-align: right;width:50%;
    }
  }
 .loadingbox{
    min-height: 25rem;
  }
  .resetquery{
    margin: .625rem .625rem 0 .625rem;
  }
  .tabletop{
    display: flex;
    flex-direction:row-reverse;
    padding: .3125rem 1rem;
  }
  .czbtn{
    margin-right: .75rem;
  }
  .czbtn2{
    margin: .3125rem 0;
  }
  .morebtn{
    height: 25.72px;
    margin: 0;
    border: none;
    background-color:#fdd835 !important;
    color: #fff;
  }

  .Paginationstyle{
    margin-top: .9375rem;
  }
  .addbtn{
    display: flex;
    flex-direction: row ;
    justify-content: flex-end;
    align-items: flex-end;
    flex-wrap: wrap;
  }
  .upload{
    display: none;
  }
  /deep/.el-dialog__body{
    padding:0 10px 10px !important;
  }
  .personalbtn{
    width: 100%;
    border: none;
    text-align: center;
  }
  .personal{
    padding: 0;
  }
</style>
