<template>
  <div>
    <div class='maxgao'>
        <q-card flat bordered class="my-card datum">
            <q-card-section class="card1">
                <span>资料审核历史流程</span>
                <el-button
                size="mini"
                type="primary"
                icon="iconfont icon-xiazai"
                @click="orderhistoryimage()">获取历史流程图</el-button>
            </q-card-section>
            
            <q-card-section>
                <tabulationdate
                :orderList='datum'
                :label='"订单审核"'
                ></tabulationdate>
            </q-card-section>
        </q-card>
        <q-card flat bordered class="my-card datum">
            <q-card-section class="card1">
                <span>订单审核历史流程</span>
                <el-button
                size="mini"
                type="primary"
                icon="iconfont icon-xiazai"
                @click="datahistoryimage()">获取历史流程图</el-button>
            </q-card-section>
            <q-card-section>
                <tabulationdate
                :orderList='orderChecks'
                :label='"资料审核"'
                ></tabulationdate>
            </q-card-section>
        </q-card>

        <!-- 历史流程图 -->
        <!-- <q-dialog v-model="card" content-style='width: 81.25rem;'>
        <q-card class="my-card" >
            <q-card-section class="imgcard">
                <div class="imgcard">
                    <img :src="codeurl" class="imgs"/>
                </div>
            </q-card-section>
        </q-card>
        position: relative;
        </q-dialog> -->
        <el-dialog :visible.sync="card" append-to-body width='95%' :modal='false'>
            <div class="imgcard" style='overflow: auto'>
                <img :src="codeurl" class="imgs"/>
            </div>
        </el-dialog>
    </div>
  </div>
</template>

<script>
import tabulationdate from '@/components/Order/tabulationdate' // 资料表格组件
import axios from 'axios'

export default {
    props:{
        datum:{ //资料审核流程
            type: Array,
            required: true
        },
        orderChecks:{ //订单审核
            type: Array,
            required: true,
            medium: false,
        }
    },
    created(){},
    data() {
    return {
        codeurl: '',
        card: false
    };
    },
    components:{
        tabulationdate
    },
    computed:{
    },
    watch:{
    },
    methods: {
        // 获取订单审核历史图
        async orderhistoryimage(){
            const datumid = this.datum[0].processInstanceId
            const params={
                processInstanceId: datumid,
            }
            const res = await axios({
                method: 'GET',
                url: 'order/orderCheck/history/image',
                params: params,
                responseType: 'arraybuffer', //将获取到的data数据变成blob形式
                headers: {
                token: sessionStorage.getItem("token")
                }
            })
            console.log(res);
            this.codeurl = `data: image/jpeg;base64,${btoa(new Uint8Array(res.data).reduce((data, byte) => data + String.fromCharCode(byte), ''))}`;
            // console.log(this.codeurl);
            this.card=true;
            // echo '<img src="data:'.$data.'">';
        },
        // 获取资料审核历史图
        async datahistoryimage(){
            const datumid = this.orderChecks[0].processInstanceId
            const params={
                processInstanceId: datumid,
            }
            const res = await axios({
                method: 'GET',
                url: 'order/orderCheck/history/image',
                params: params,
                responseType: 'arraybuffer', //将获取到的data数据变成blob形式
                headers: {
                token: sessionStorage.getItem("token")
                }
            })
            console.log(res.data);
            this.codeurl = `data: image/jpeg;base64,${btoa(new Uint8Array(res.data).reduce((data, byte) => data + String.fromCharCode(byte), ''))}`;
            console.log(this.codeurl);
            this.card=true;
            // echo '<img src="data:'.$data.'">';
        }
    },
};
</script>

<style lang='less' scoped>
.maxgao{
    max-height: 50vh;
    overflow: auto;
}
.datum{
    margin: 10px 0;
}
.card1{
    padding: 15px 15px 0 15px;
    display: flex;
    justify-content: space-between;
}
.imgcard{
    display: flex;
    justify-content: center;
    align-items: center;
}
.you{
    display: flex;
    justify-content: flex-end;
}
</style>
