<template>
  <div>
    <q-form ref="myForm" class="add">
      <div class='maxgao'>
        <!-- 所有的文字内容 -->
        <q-card flat bordered class="my-card add">
            <q-card-section>
                <checkAllContent
                    :checkAllData.sync='checkAllDatas'
                    :checkData.sync='checkDatas'
                />
            </q-card-section>
        </q-card>
        
        <!-- 检查报告影像资料发票信息综合 -->
        <belowTablebox
            :checkAllData.sync='checkAllDatas'
            :checkData.sync='checkDatas'
            :ordervisible.sync='ordervisible'
            @refresh='refresh'
            @yxdownload='yxdownload'
        />
      </div>
      <div class="caozuobtn">
          <q-btn
            class="checkbtn"
            label="取消"
            color="blue-6"
            @click="closedialog"
          />
          <q-btn
            :disable='checkDatas.disable'
            class="checkbtn"
            label="驳回"
            color="negative"
            @click="reject"
          />
          <q-btn
            :disable='checkDatas.disable'
            class="checkbtn"
            label="通过"
            color="blue-6"
            @click="agreecheck"
          />
      </div>
    </q-form>
    
    <!-- 驳回弹出框 -->
    <q-dialog v-model="card" persistent @hide='hide'>
      <q-card style="width: 500px">
        <q-card-section>
          <div class="text-h6">驳回理由</div>
        </q-card-section>
        <q-card-section class="q-pt-none">
            <q-form ref="reject">
                <q-input
                class='rejectinput'
                :dense='true' 
                outlined 
                type="textarea"
                v-model="rejectreason" 
                label="驳回理由" 
                lazy-rules 
                :rules="[required.reject]"
                />
            </q-form>
        </q-card-section>

        <q-card-actions align="right" class="text-primary">
          <q-btn flat label="取消" v-close-popup />
          <q-btn :disable='rejectreason==""? true : false' label="驳回" @click='rejectcheck' color="negative"/>
        </q-card-actions>
      </q-card>
    </q-dialog>

    <!-- 普通影像资料下载链接生成弹窗 -->
    <yxDown
        ref='yxDown'
        :downdata.sync='downdata'
        @downhide='downhide'
    />
  </div>
</template>

<script>
import { Loading } from 'element-ui';

import { informationdownload } from '@/api/components/informationdownload.js' //影像资料下载
import yxDown from '@/components/Down/informationDown.vue' // 影像链接生成弹窗组件

import { orderCheckagree,comboCheckreject,orderCheck } from '@/api/orderCheck.js'; // 订单审核接口

import { getprovince,getcity,gethosplist,getdepart,getcombo,getoptions } from '@/api/components/getCity.js'; // 获取城市接口
import { mapState } from 'vuex' //vuex获取数据
import axios from 'axios'

import { downloads } from '@/api/Order.js'; // 用户订单新增接口

// import tabulationdate from '@/components/Order/tabulationdate' // 资料表格组件
import surgeryDate from '@/components/Order/surgeryDate' // 时间选择组件
import selectq from '@/components/Order/select' // 普通下拉框组件
import selectp from '@/components/Order/select/provinceselect.vue' // 省份下拉框组件
import selectc from '@/components/Order/select/cityselect.vue' // 城市下拉框组件
import selecth from '@/components/Order/select/hospselect.vue' // 医院下拉框组件
import selectd from '@/components/Order/select/departsselect.vue' // 科室下拉框组件
import comboselect from '@/components/Order/select/comboselect.vue' // 套餐下拉框组件
import checkAllContent from '@/components/Commoncomponents/checkbox.vue' // 所有文字信息综合
import belowTablebox from '@/components/Commoncomponents/belowTablebox.vue' // 所有文字信息综合

import  {required}  from '@/api/components/required.js' // 表单验证规则

import { makeDebounce } from "@/utils/debounce.js";  
import { notifys } from '@/api/components/Notify.js'

export default {
    props:{
        checkAlllist: { //所有城市省份医院信息
            type: Object,
            required: true,
        },
        checkAllData:{ //修改订单的数据
            type: Object,
            required: true,
        },
        checkData:{ //所选的城市
            type: Object,
            required: true,
        }
    },
    created(){
    },
    data() {
        return {
            ordervisible: false,

            text:'',
            required,
            card: false, // 控制驳回文本弹出框
            rejectreason: '', // 绑定驳回理由

            options:{
                target:'document.body',
                text:'下载链接获取中,如果时间较长请耐心等待',
            },

            downdata:{
                downconfig: false,
                downcheckprogress: 0, //进度条
                downcheckprogressLabel: '', //进度百分比
                downfileName: '', // 文件名
                downjindu: null, //用于储存定时器
                downstepName:'', //压缩步骤
                timerList:[],
                downdataA:true, //
                downdataB: true,
            },
        };
    },
    components:{
        selectq, // 普通下拉组件
        selectp, // 省份下拉框组件,
        selectc, //城市下拉框
        selecth, // 医院下拉框
        selectd, // 科室下拉框
        comboselect, //套餐下拉框
        surgeryDate, // 手术时间
        yxDown, //影像链接生成弹窗组件
        checkAllContent, //文字内容综合box
        belowTablebox, //检查报告影像资料发票信息综合

        // tabulationdate // 资料表格
    },
    computed:{
        ...mapState([
            'orderStatus', //订单状态
            'orderType',//订单类型
            'payStatus', // 支付状态
            'isPay', // 代理商是否收款
        ]),
        checkAlllists:{
            get(){
                return this.checkAlllist
            },
            set(v){
                this.$emit('update:checkAlllist',v)
            }
        },
        checkAllDatas:{ //agreeAllData
            get(){
                return this.checkAllData
            },
            set(v){
                this.$emit('update:checkAllData',v)
            }
        },
        checkDatas:{  //agreeData
            get(){
                return this.checkData
            },
            set(v){
                this.$emit('update:checkData',v)
            }
        }
    },
    watch:{
    },
    methods: {
        //刷新影像资料
        refresh:makeDebounce(async function(row) {
            console.log('刷新',this.checkAllDatas);
            this.ordervisible = true
            setTimeout(async() =>{
                const {data:res} = await orderCheck({id:this.checkAllDatas.orderId})
                console.log(res);
                if(res.code===20000){
                    notifys('positive',res.message)
                    this.checkDatas.dcmReportFilesList =[] // 清空影像资料
                    // console.log(this.checkData.reject);
                    if(res.data.order.informationList){
                        res.data.order.informationList.map(item=>{
                            if(item.informationType == 1){
                                this.checkDatas.dcmReportFilesList.push(item)
                            }
                        })
                    }
                    this.ordervisible = false
                }else{
                    notifys('negative',res.message)
                    this.ordervisible = false
                }
            },1000)
           
        },300),
       
        // },

        //影像资料下载
        yxdownload:makeDebounce(async function(row) {
            informationdownload(
                row, //所下载的id信息
                this.downdata
            )
        },500),

        //下载生成进度条关闭时
        downhide(){
            if (this.downdata.downjindu) {
                console.log("结束了");
                this.downdata.timerList.forEach((item, index) => {
                    window.clearInterval(item);
                })
                this.downdata.timerList = [];
                this.downdata.downjindu = 0;
            }
            this.downdata.downconfig =false
            this.downdata.downdataA = false
        },

        closedialog(){
            this.$emit('closedialog')
            console.log('A');
        },
        // 点击同意时
        agreecheck(){
            this.$q.dialog({
                title: '通过',
                message: `你确定要让此订单审核任务通过吗?`,
                ok: { label: '确定'},
                cancel: { label: '取消',flat: true},
            }).onOk(async() => {
            try{
                const { data: res } = await orderCheckagree({ ids: this.checkDatas.orderCheckId })
                console.log(res);
                if(res.code===20000){
                    this.$emit('checksuccess')
                    this.$emit('closedialog')
                    notifys('positive',res.message)
                }else{
                    notifys('negative',res.message)
                }
                }catch (e){
                    console.log(e);
                }
            })
        },
        // 点击驳回时
        reject(){
            this.card=true
        },
        // 提交驳回
        async rejectcheck(){
            this.$q.dialog({
                title: '驳回',
                message: `你确定要驳回此订单吗?`,
                ok: { label: '确定'},
                cancel: { label: '取消',flat: true},
            }).onOk(async() => {
            try{
                const { data: res } = await comboCheckreject({ 
                    id: this.checkDatas.orderCheckId,
                    comment: this.rejectreason
                })
                console.log(res);
                if(res.code===20000){
                    this.card = false
                    this.rejectreason=''
                    this.$refs.reject.resetValidation()  //需要修改点击X时 需要清理驳回信息
                    this.$emit('checksuccess')
                    this.$emit('closedialog')
                    notifys('positive',res.message)
                }else{
                    notifys('negative',res.message)
                }
                }catch (e){
                    console.log(e);
                }
            })
        },
        hide(){
            this.rejectreason=''
        }
    },
};
</script>

<style lang='less' scoped>
.maxgao{
    // border-radius: 3px;
    // background: rgba(255, 255, 255, 0.5);
    // box-shadow: inset 0 0 10px rgb(0 0 0 / 20%);
    // margin: .0625rem;
    // padding: .0625rem;
    max-height: 50vh;
    overflow: auto;
}
/deep/.el-dialog__body{
    padding: 0 10px 10px !important;
}
/deep/.el-dialog__header {
    padding: 20px 20px 0 !important;
}
.my-card{
  width: 100%;
  margin-bottom: .5rem;
}
.add {
    width: 100%;
    margin-left: 0;
    margin-top: 0.1875rem;
}
.caozuobtn {
    margin: .9375rem 0 0 0;
    display: flex;
    justify-content: space-between;
    .checkbtn{
        width: 6.25rem;
    }
}
.q-th{
    height: 0;
}
.imgcard{
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
}
.rejectinput{
    max-height: 12.5rem;
    width: 12.5rem;
    width: 100%;
    margin-left: 0;
    margin-top: 0.1875rem;
}

</style>
