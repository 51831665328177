<template>
  <div>
       <!-- 列表区域 -->
    <el-table
      ref="hiestory"
      max-height='260'
      :data="orderList"
      tooltip-effect="dark"
      style="width: 100%">
        <!-- 任务处理人 -->
        <el-table-column label="任务处理人" prop="userName"></el-table-column>
        <!-- 任务开始时间 -->
        <el-table-column label="任务开始时间" prop="startTime"></el-table-column>
        <!-- 任务结束时间 -->
        <el-table-column label="任务结束时间" prop="endTime"></el-table-column>
        <!-- 当前任务名称 -->
        <el-table-column label="当前任务名称" prop="taskName"></el-table-column>
        <!-- <el-table-column label="">
          <template slot-scope="scope">
            <el-button
            size="mini"
            type="primary"
            icon="iconfont icon-xiazai"
            @click="Checkhistoryimage(scope.row)">获取历史流程图</el-button>
          </template>
        </el-table-column> -->
    </el-table>
    
  </div>
</template>

<script>

export default {
    props:{
        orderList:{
            type: Array,
            required: true
        },
        label:{
            type: String
        },
    },
  created(){},
  data() {
    return {
    };
  },
  components:{},
  computed:{
  },
  watch:{
  },
  methods: {
    
  },
};
</script>

<style lang='less' scoped>

</style>
